import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#9f6518', '#ffeb19', '#FBC41C', '#F5FF3C', '#f08418'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
};

export const buyFeatureTitleStyle = new PIXI.TextStyle({
  fontSize: 78,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '500',
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  dropShadow: true,
  dropShadowColor: '#2a2755',
  dropShadowAngle: 0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  stroke: '#4f3480',
  strokeThickness: 7,
  lineJoin: 'round',
  padding: 12,
});

export const buyFeatureTitleStyleB = new PIXI.TextStyle({
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '900',
  align: 'center',
  fill: 'white',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  padding: 8,
});

export const buyFeatureTitleStyleC = new PIXI.TextStyle({
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '900',
  align: 'center',
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const totalCostTextStyle = new PIXI.TextStyle({
  fontSize: 60,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  fill: ['#0088ff', '#0000ff', '#000088'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const totalCostTextAmountStyle = new PIXI.TextStyle({
  fontSize: 50,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const amountTextStyle = new PIXI.TextStyle({
  fontSize: 43,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  fill: 'white',
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 500,
  breakWords: true,
});

export const betValueStyle = new PIXI.TextStyle({
  fontSize: 40,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 50,
  fill: 'white',
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const angelAndDevilTextStyle = new PIXI.TextStyle({
  fontSize: 30,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 30,
  fill: 'white',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 300,
  breakWords: true,
  align: 'center',
});

export const angelTextStyle = new PIXI.TextStyle({
  fontSize: 30,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 30,
  fill: 'white',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 300,
  breakWords: true,
  align: 'center',
});

export const devilTextStyle = new PIXI.TextStyle({
  fontSize: 30,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 30,
  fill: 'white',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 300,
  breakWords: true,
  align: 'center',
});

export const buyFeatureActiveTextStyle = new PIXI.TextStyle({
  fontSize: 38,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  fill: 'white',
  fillGradientType: 0,
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 400,
  breakWords: true,
  align: 'center',
});

export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontSize: 34,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  fill: '#a09dcd',
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 400,
  breakWords: true,
  align: 'center',
});
export const totalCostActiveTextStyle = new PIXI.TextStyle({
  fontSize: 38,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  dropShadow: true,
  dropShadowDistance: 2,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 400,
  breakWords: true,
  align: 'center',
});

export const totalCostDisableTextStyle = new PIXI.TextStyle({
  fontSize: 34,
  fontFamily: 'Verdana',
  whiteSpace: 'normal',
  lineHeight: 40,
  fill: ['#685322', '#a08b54', '#c8b279', '#ab9363', '#a6925c'],
  fillGradientStops: [0, 0.4, 0.48, 0.59, 1],
  dropShadowDistance: 2,
  dropShadow: true,
  lineJoin: 'round',
  fontWeight: '600',
  wordWrap: true,
  wordWrapWidth: 400,
  breakWords: true,
  align: 'center',
});

export const WinCountUpMessageStyle = new PIXI.TextStyle({
  fontSize: 55,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '900',
  align: 'center',
  fill: 'white',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
  stroke: '#af435d',
  strokeThickness: 6,
});
