import * as pixi from 'pixi.js';

import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

//
// export declare const PIXI: pixi;
export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface IMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface Cascade {
  multiplier: number;
  winPositions: number[][];
  winAmounts: number[];
}
export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        cascade: Cascade[];
        multiplier: number;
        scatterWins: {
          rewards: {
            multiplier?: number;
          }[];
        }[];
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
      metadata?: IMetadata;
    };
    settled: {
      amount: number;
      currency: string;
      metadata?: IMetadata;
    };
  };
}
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  betId: string;
  rounds: number;
  roundsPlayed: number;
  cascadeMultiplier: number;
  reelSetId: string;
  status: BonusStatus;
  gameMode: GameMode;
  isFreeBet?: boolean;
  totalWinAmount: number;
  coinAmount: number;
  coinValue: number;
  data: UserBonusData;
};
export enum Layout {
  '6x6' = '6x6',
  '5x6' = '5x6',
}
export enum GameMode {
  REGULAR,
  ANGEL,
  DEVIL,
  ANGEL_AND_DEVIL,
  BUY_FEATURE_ANGEL,
  BUY_FEATURE_DEVIL,
  BUY_FEATURE_ANGEL_AND_DEVIL,
  FREE_ROUND_BONUS,
}
export const reelSets = {
  [GameMode.REGULAR]: '991ceee2-fdec-4ee5-87c6-57ed5c276dab',
  [GameMode.ANGEL]: '8233931f-b1e6-4947-8efe-f50767321c34',
  [GameMode.DEVIL]: 'c4e8a047-4829-473f-a572-a72ba73cef53',
  [GameMode.ANGEL_AND_DEVIL]: '4d3ea44d-096e-4bc9-9933-2de9d1141307',
  [GameMode.BUY_FEATURE_ANGEL]: '80b09e28-9d4d-4dd5-80aa-5b1899785667',
  [GameMode.BUY_FEATURE_DEVIL]: '1ccafaa9-7811-47a9-b4ca-f46d06a3c968',
  [GameMode.BUY_FEATURE_ANGEL_AND_DEVIL]: '2cd37360-770e-4f2f-b0c3-0b9f064f7ec4',
  [GameMode.FREE_ROUND_BONUS]: '991ceee2-fdec-4ee5-87c6-57ed5c276dab',
};
export const bonusesId = {
  [GameMode.ANGEL]: '65586dd4-2da5-4f0b-aa48-8380209e7626',
  [GameMode.DEVIL]: '027e966e-f7de-43b0-9731-2c4dd79230ae',
  [GameMode.ANGEL_AND_DEVIL]: '33da68b3-10f8-4fb9-85c6-fb3ceb20b25b',
  [GameMode.BUY_FEATURE_ANGEL]: 'e769c88d-3a22-46d9-843d-c678c3da9e04',
  [GameMode.BUY_FEATURE_DEVIL]: '562e3c00-eab0-4657-b9f2-0e54cc004d5e',
  [GameMode.BUY_FEATURE_ANGEL_AND_DEVIL]: 'b23611f2-f86d-4dc8-9913-ead10df8dea2',
  [GameMode.FREE_ROUND_BONUS]: '498b8144-f0f5-48be-a13f-18448c05b75b',
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: PIXI.TextStyle;
  mobileTitleStyle?: PIXI.TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: PIXI.TextStyle;
  mobileSubtitleStyle?: PIXI.TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: PIXI.TextStyle;
  mobileAdditionalStyle?: PIXI.TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  FORCE_STOP_AUTOPLAY = 'FORCE_STOP_AUTOPLAY',
  ANTICIPATION_STARTS = 'anticipationStarts',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  COUNT_UP_SCATTER_WIN_AMOUNT = 'countUpScatterWinAmount',
  CHANGE_MODE = 'changeMode',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_FREE_SPIN_MESSAGE_BANNER = 'createFreeSpinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_COINS = 'hideCoins',
  HIDE_COUNT_UP = 'hideCountUp',
  HIDE_FRAME = 'hideFrame',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  NEXT_CASCADE = 'nextCascade',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  MOVE_MULTIPLIER = 'moveMultiplier',
  REELS_STOPPED = 'reelsStopped',
  REEL_LANDED = 'reelLanded',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_ANGEL_ATTACK = 'startAngelAttack',
  START_DEVIL_ATTACK = 'startDevilAttack',
  START_SCATTER_WIN_ANIMATION = 'startScatterWinAnimation',
  START_DESTROY_ANGEL_LOCKS = 'startDestroyAngelLocks',
  START_DESTROY_DEVIL_LOCK = 'startDestroyDevilLock',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_CHARACTER_ANIMATION = 'startCharacterAnimation',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SHOW_FRAME = 'showFrame',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  UPDATE_MULTIPLIER_VALUE = 'updateMultiplierValue',
  UPDATE_FREE_SPINS_TITLE = 'updateFreeSpinsTitle',
  POST_RENDER = 'postrender',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  OPEN_BUY_FEATURE_CONFIRM_POPUP_BG = 'openBuyFeatureConfirmPopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP_BG = 'closeBuyFeatureConfirmPopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  END_WAITING_ANIMATION = 'removeErrorHandler',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  OPEN_POPUP = 'OPEN_POPUP',
  CLOSE_POPUP = 'CLOSE_POPUP',
  END_MODE_CHANGE_FADE = 'endModeFadeChange',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

declare global {
  interface Window {
    eventManager: PIXI.utils.EventEmitter<string | symbol>;
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}
