const font = 'NotoSans-SemiCondensedBold';

export const titleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const mobileTitleStyle = {
  fontSize: 85,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  letterSpacing: 0,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const subtitleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 2,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  wordWrapWidth: 60,
  dropShadowDistance: 2,
  letterSpacing: 0,
  lineJoin: 'round',
  padding: 12,
};

export const mobileSubtitleStyle = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#f0fb4c', '#e8a329', '#936419', '#b48836'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  stroke: '#573083',
  strokeThickness: 6,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  wordWrap: true,
  wordWrapWidth: 100,
  dropShadowDistance: 2,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 12,
};

export const additionalStyle = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  lineJoin: 'round',
  letterSpacing: 0,
};

export const winTitleStyle = {
  dropShadowDistance: '',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontFamily: 'Verdana',
  fontSize: 180,
  whiteSpace: 'pre-line',
  stroke: '#573083',
  strokeThickness: 6,
  fontWeight: 500,
};

export const mobileWinTitleStyle = {
  dropShadowDistance: '',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontFamily: 'Verdana',
  fontSize: 140,
  fontWeight: 500,
  whiteSpace: 'pre-line',
  stroke: '#573083',
  strokeThickness: 4,
};

export const winSubtitleStyle = {
  fontSize: 180,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fillGradientType: 0,
  letterSpacing: 0,
  fontWeight: 'bold',
  lineJoin: 'round',
  stroke: '#573083',
  strokeThickness: 6,
};

export const mobileWinSubtitleStyle = {
  fontSize: 140,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontWeight: 'bold',
  lineJoin: 'round',
  stroke: '#573083',
  strokeThickness: 6,
};

export const btnStyle = {
  fontSize: 60,
  fontFamily: font,
  fill: '0xffffff',
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  dropShadow: true,
  letterSpacing: 0,
};

export const mobileBtnStyle = {
  fontSize: 80,
  fontFamily: font,
  fill: '0xffffff',
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  dropShadow: true,
  letterSpacing: 0,
};
